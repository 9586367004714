<template>
  <div class="home page-wrapper">
    <div class="banner-wrapper">
      <div class="slide-wrapper" ref="scroll">
        <div class="slide-content">
          <div class="slide-page">
            <img :src="res('home_banner.png')" />
          </div>
          <div class="slide-page">
            <img :src="res('home_banner2.png')" />
          </div>
        </div>
      </div>
      <div class="dots-wrapper">
        <span
          @click="goToPage(num - 1)"
          class="dot"
          v-for="num in nums"
          :key="num"
          :class="{'active': currentPageIndex === (num - 1)}"></span>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <el-row class="section1" type="flex">
          <el-col :span="24" :md="{span:8}">
            <div class="section1-about">
              <img class="section1-image" src="../../assets/home_about.png" />
              <img class="section1-image-decoration" src="../../assets/home_about_line.png" />
            </div>
          </el-col>
          <el-col :span="24" :md="{span:16}">
            <p>{{$t("home.title")}}</p>
            <p>{{$t("home.desc")}}</p>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="section2">
      <div class="container">
        <div class="row">
          <el-row>
            <el-col :span="24" :sm="{span: 12, offset: 6}" :md="{span: 10, offset: 0}" >
              <img :src="res('home_detail.png')" />
            </el-col>
            <el-col :span="24" :sm="{span: 12, offset: 6}" :md="{span: 10, offset: 4}" >
              <h3>{{$t("home.section")}}</h3>
              <p>{{$t("home.sectionp1")}}</p>
              <p>{{$t("home.sectionp2")}}</p>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="section3">
          <h3>{{$t("home.smart")}}</h3>
          <h4>{{$t("home.smartp1")}}</h4>
        </div>
      </div>
    </div>

    <div class="section4-w" style="overflow:hidden;">
      <el-row class="section4" :gutter="16">
        <el-col :span="8" :xs="{ span:24 }">
          <img src="../../assets/home_zhineng_yiliao.png" alt="">
          <h4>{{$t("home.sec1")}}</h4>
        </el-col>
        <el-col :span="8" :xs="{ span:24 }">
          <img :src="res('home_quanwu_zhineng.png')" alt="">
          <h4>{{$t("home.sec2")}}</h4>
        </el-col>
        <el-col :span="8" :xs="{ span:24 }">
          <img src="../../assets/home_zhineng_dengpao.png" alt="">
          <h4>{{$t("home.sec3")}}</h4>
        </el-col>
      </el-row>
    </div>


    <div class="container">
      <div class="row">
        <div class="section5">
          <h3>{{$t("home.service")}}</h3>
          <h4>{{$t("home.servicedesc")}}</h4>
        </div>
        <div class="section6">
          <el-row>
            <el-col :span="12" :sm="{span: 8}" :md="{ span: 4}">
              <img src="../../assets/home_xmpg.png" alt="" style="transform:translateX(12px)">
              <h4>{{$t("home.character1")}}</h4>
              <p>{{$t("home.character1p1")}}</p>
              <p>{{$t("home.character1p2")}}</p>
            </el-col>
            <el-col :span="12" :sm="{span: 8}" :md="{ span: 4, offset: 1}">
              <img src="../../assets/home_sjkf.png" alt="">
              <h4>{{$t("home.character2")}}</h4>
              <p>{{$t("home.character2p1")}}</p>
              <p>{{$t("home.character2p2")}}</p>
            </el-col>
            <el-col :span="12" :sm="{span: 8}" :md="{ span: 4, offset: 1}">
              <img src="../../assets/home_csyz.png" alt="">
              <h4>{{$t("home.character3")}}</h4> 
              <p>{{$t("home.character3p1")}}</p>
              <p>{{$t("home.character3p2")}}</p>
            </el-col>
            <el-col :span="12" :sm="{span: 8}" :md="{ span: 4, offset: 1}">
              <img src="../../assets/home_ysfb.png" alt="">
              <h4>{{$t("home.character4")}}</h4>
              <p>{{$t("home.character4p1")}}</p>
              <p>{{$t("home.character4p2")}}</p>
            </el-col>
            <el-col :span="12" :sm="{span: 8}" :md="{ span: 4, offset: 1}">
              <img src="../../assets/home_shfw.png" alt="">
              <h4>{{$t("home.character5")}}</h4>
              <p>{{$t("home.character5p1")}}</p>
              <p></p>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BScroll from '@better-scroll/core'
import Slide from '@better-scroll/slide'
import { res } from '@/common'
BScroll.use(Slide)
// @ is an alias to /src
// import {throttle} from '@/common/index'
export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      imgHeight: '720px',
      currentPageIndex: 0,
      nums: 2
    }
  },
  created() {
    // this.getHeight_d = throttle(20, this.getHeight)
    // window.addEventListener('resize', this.getHeight_d)
    // this.getHeight()
  },
  mounted() {
    this.initScroll()
  },
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
    getHeight() {
      this.imgHeight = (window.innerWidth / 1920 * 720).toFixed(0) + 'px'
    },
    async initScroll() {
      await this.$nextTick()
      const slide = new BScroll(this.$refs.scroll, {
        scrollX: true,
        scrollY: false,
        slide: true,
        momentum: false,
        bounce: false,
        probeType: 3
      })
      this.slide = slide
      this.slide.on('slideWillChange', (page) => {
        this.currentPageIndex = page.pageX
      })
    },
    goToPage(idx) {
      this.slide.goToPage(idx, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: var(--font-large-size);
  font-weight: normal;
  text-align: center;
}

h4 {
  font-size: var(--font-medium-size);
  font-weight: normal;
  text-align: center;
}

.section1 {
  margin: var(--margin-normal) 0;
  align-items: stretch;
  flex-wrap: wrap;
  .el-col:first-child {
    display: flex;
    align-items: flex-end;
  }
  .section1-about {
    position: relative;
    margin-bottom: 12px;
    .section1-image-decoration {
      position: absolute;
      left: 75px;
      bottom: 0;
    }
  }

  p {
    line-height: 2;
    font-size: var(--font-normal-size);
  }
}

.section2 {
  background-color: #ECECEC;
  padding: var(--margin-max-max) 0;
  position: relative;

  .el-col {
    height: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }

  .el-col:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    font-size: var(--font-large-size);
    font-weight: normal;
    text-align: center;
    margin-top: var(--margin-large);
    margin-bottom: var(--margin-large);
  }

  p {
    line-height: 2;
    font-size: var(--font-normal-size);
  }
}

.section3, .section5 {
  h3 {
    margin-top: var(--margin-normal);
  }
  h4 {
    margin-top: var(--font-medium-size);
  }
}

.section4 {
  margin-top: var(--margin-small);

  .el-col {
    margin-top: var(--margin-min);
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    h4 {
      position: absolute;
      bottom: 0;
      left: 8px;
      height: 20.3%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% - 16px);
      font-size: var(--font-medium-size);
    }
  }
}

.section6 {
  margin: var(--margin-normal) 0 var(--margin-max-max);

  .el-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 240px;

    h4 {
      font-size: 20px;
      margin-top: 12px;
    }

    p {
      width: 100%;
      text-align: center;
      margin-top: 8px;
      font-size: var(--font-normal-size);
      color: rgba(0, 0, 0, .56);
    }
  }
}

.banner-wrapper {
  position: relative;
  margin: 0 auto;
  .slide-wrapper {
    min-height: 1px;
    overflow: hidden;
    .slide-content {
      white-space: nowrap;
      font-size: 0;
      .slide-page {
        display: inline-block;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }

  .dots-wrapper {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    .dot {
      display: inline-block;
      margin: 0 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #EEE;
      transition: all .3s ease;
      cursor: pointer;
      &.active {
        width: 20px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .section6 {
    img {
      height: 67px;
    }
  }
}
</style>
