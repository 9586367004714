<template>
  <div class="notfound">
    <icon name="icon-browser-"></icon>
    <el-button @click="$router.go(-1)" type="default" round>{{$t("notfould.back")}}</el-button>
    <div style="margin-top:12px"><el-button @click="$router.push('/home')" type="primary" round>前往首页</el-button></div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.notfound {
  display: flex;
  align-items: center;
  flex-direction: column;
  .icon {
    font-size: 128px;
    margin: 48px 0;
  }
}
</style>