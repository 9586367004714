<template>
  <footer class="app-footer">
    <div class="container">
      <div class="row">
        <el-row>

          <el-col :xs="24" :sm="8">
            <div class="footer-app">
              <div @click="gotoDownload" class="qrcode-name">
                <img :src="res('app_qrcode.png')" alt="">
                <p >{{$t("footer.downloadapp")}}</p>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="16">

            <div class="footer-nav">
              <div class="footer-nav-contact">
                <p>{{ $t("footer.company") }}</p>
                <p>{{ $t("footer.contact") }}</p>
                <p>{{ $t("footer.phone") }}</p>
                <p>{{ $t("footer.email") }}</p>
                <p>{{ $t("footer.address") }}</p>
              </div>
              <img class="footer-nav-logo" src="../assets/qrcode_for_gh_55f843d0e539_258.jpg" />
            </div>
          </el-col>

        </el-row>

      </div>

    </div>
    <p class="footer-icp">{{ $t("footer.icp") }}<a class="icp" target="_blank" href="http://beian.miit.gov.cn">{{
      beian
    }}</a></p>
  </footer>
</template>

<script>
import { res } from '@/common'
export default {
  created() {
    let beian = '粤ICP备2021076852号'
    if (location.host.includes('aimore.shop')) {
      beian = '粤ICP备2023000942号-1'
    } else if (location.host.includes('ampai.life')) {
      beian = '粤ICP备2023000942号-2'
    } else if (location.host.includes('ampai.shop')) {
      beian = '粤ICP备2023000942号-3'
    }
    this.beian = beian
  },
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
    gotoDownload() {
      if (this.$i18n.locale == 'zh-CN') {
        window.open('https://life.aimore.com/appnet/aimorehome/index.htm')
      } else {
        window.open('https://life.aimore.com/appnet/aimorehome/index_en.htm')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  background-color: #2F2E33;
}

.footer-nav {
  height: 218px;
  display: flex;
  align-items: center;
  position: relative;

  .footer-nav-logo {
    height: 124px;
    width: 124px;
    margin-left: 20px;
    flex: 0 0 auto;
  }

  .footer-nav-contact {
    height: 124px;
    color: #EEE;
    width: 0;
    flex: 1 1 auto;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: var(--font-normal-size);
  }
}

.icp {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #DDD;
  }
}

.footer-icp {
  // position: absolute;
  // left: 50%;
  // bottom: 12px;
  // transform: translateX(-50%);
  font-size: 12px;
  color: #BBB;
  line-height: 40px;
  text-align: center;
  background-color: #111;
}

@media screen and (max-width: 500px) {
  .footer-nav {}
}

.footer-app {
  height: 218px;
  padding: 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: #eee;
  position: relative;

  p {
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
  }

  img{
    cursor: pointer;
  }
}

.qrcode-name {
  width: 124px;
  position: relative;

  img {
    width: 124px;
  }

  p {
    position: absolute;
    bottom: -19px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 768px) {
  .footer-app {
    justify-content: flex-end;
    padding: 0;
  }
}
</style>