import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import zh from './zh-CN.json'
import en from './en-US.json'
import { getLanguage, setLanguage } from '@/common/cache'

// var lang = navigator.language == 'zh-CN' ? 'zh-CN' : 'en-US'
var lang = 'en-US'

if (getLanguage()) {
  lang = getLanguage()
}

const i18n = new VueI18n({
  locale: lang,
  messages: {
    'en-US': en,
    'zh-CN': zh
  },
  fallbackLocale: 'en-US'
})

export default i18n
