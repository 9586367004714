<template>
  <div class="voice page-wrapper">
    <el-image
      class="banner"
      style="width: 100%;"
      :src="res('voice_banner.png')"
      fit="scale-down">
    </el-image>
    <div class="container">
      <div class="row">
        <el-row class="section1" :gutter="24">
          <el-col :span="12" :xs="{ span: 24 }">
            <img src="../../assets/voice_ys.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{ span: 24 }">
            <h3>
              {{$t("cloud.title")}}
            </h3>
            <p>
              {{$t("cloud.desc")}} 
            </p>
          </el-col>
        </el-row>
        <h2 class="section2">{{$t("cloud.advantage")}}</h2>
        <el-row class="section3">
          <el-col :span="6" :xs="{span: 12}" class="feature">
            <img src="../../assets/voice_quh.png" alt="">
            <label for="">{{$t("cloud.feature1")}}</label>
          </el-col>
          <el-col :span="6" :xs="{span: 12}" class="feature">
            <img src="../../assets/voice_dpl.png" alt="">
            <label for="">{{$t("cloud.feature2")}}</label>
          </el-col>
          <el-col :span="6" :xs="{span: 12}" class="feature">
            <img src="../../assets/voice_dzd.png" alt="">
            <label for="">{{$t("cloud.feature3")}}</label>
          </el-col>
          <el-col :span="6" :xs="{span: 12}" class="feature">
            <img src="../../assets/voice_qll.png" alt="">
            <label for="">{{$t("cloud.feature4")}}</label>
          </el-col>
        </el-row>
        <h2 class="section4">{{$t("cloud.voice")}}</h2>
        <el-row class="section5">
          <el-col :span="4" :xs="{ span: 6 }">
            <el-image :src="require('../../assets/xiaoai_tongxue.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocxiaoai")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/xiaoyi_zhushou.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocxiaoyi")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/siri.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocapple")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6 }">
            <el-image :src="require('../../assets/tiaomao_jingling.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.voctianmao")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/xiaodu.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocxiaodu")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/huawei_hilink.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vochilink")}}</label>
          </el-col>
        </el-row>
        <el-row class="section6">
          <el-col :span="4" :xs="{ span: 6 }">
            <el-image :src="require('../../assets/jd_dingdong.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocdingdong")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/jd_jingyuzuo.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocjingyu")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/vivo_xiaov.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocjovi")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6 }">
            <el-image :src="require('../../assets/oppo_xiaoo.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocxiaoou")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/google_assistant.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocguge")}}</label>
          </el-col>
          <el-col :span="4" :xs="{ span: 6, offset: 3 }">
            <el-image :src="require('../../assets/amazon_alexa.png')" fit="scale-down"> </el-image>
            <label for="">{{$t("cloud.vocamazon")}}</label>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { res } from '@/common'

export default {
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
  }
}
</script>

<style lang="scss" scoped>
.section1 {
  margin: 40px 0;
  .el-col {
    position: relative;
    img {
      width: 100%;
      max-width: 500px;
    }
  }
  .el-col:last-child {
    h3 {
      font-size: 24px;
      font-weight: normal;
      margin: 40px 0;
      text-align: center;
    }
    p {
      line-height: 1.66;
    }
  }
}
.section2 {
  margin: 80px 0 40px 0;
  text-align: center;
  font-weight: normal;
}
.section3 {

  & > .el-col {
    
    margin: var(--margin-normal) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    label {
      display: block;
      text-align: center;
      margin-top: 12px;
    }
  }
}
.section4 {
  text-align: center;
  margin: 80px 0 40px;
  font-weight: normal;
}
.section5,
.section6 {
  .el-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: var(--margin-normal);
    overflow: visible;
    height: 110px;
  }
  .el-image {
    height: 80px;
  }
  label {
    display: block;
    text-align: center;
    margin-top: 12px;
    font-size: var(--font-normal-size);
    white-space: nowrap;
    overflow: visible;
  }
}

.section6 {
  margin: 24px 0 80px;
}

@media screen and (max-width: 991px) {
  .section3 {
    img {
      height: 67px;
    }
  }
  .section5 .el-image, .section6 .el-image {
    height: 56px;
  }
}
</style>