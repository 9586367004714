<template>
  <div>
    <app-header></app-header>
    <transition name="el-fade-in-linear">
    <router-view></router-view>
    </transition>
    <app-footer></app-footer>
    <scroll-top></scroll-top>
  </div>
</template>

<script>
import AppFooter from './AppFooter'
import AppHeader from './AppHeader'
import ScrollTop from '@/components/ScrollTop.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    ScrollTop
  },
}
</script>

<style lang="scss">

</style>