<template>
  <div class="cert page-wrapper">
    <el-image
      class="banner"
      style="width: 100%;"
      :src="res('cert.png')"
      fit="scale-down">
    </el-image>

    <div class="">
      <div class="">
        <el-row class="section1">
          <el-col :span="24" :md="{span: 6, offset: 2}" @click.native="$router.push('/cert/ffs')">
            <el-image style="width: 100%;" fit="scale-down" :src="require('../../assets/ffs.png')"></el-image>
            <div class="desc">
              <p>{{$t("cert.ffs")}}</p>
              <p>{{$t("cert.ffsport")}}</p>
            </div>
          </el-col>
          <el-col :span="24" :md="{span: 6, offset: 1}" @click.native="$router.push('/cert/wwga')">
            <el-image style="width: 100%;" fit="scale-down" :src="require('../../assets/wwga.png')"></el-image>
            <div class="desc">
              <p>{{$t("cert.wwga")}}</p>
              <p>{{$t("cert.wwgaport")}}</p>
            </div>
          </el-col>
          <el-col :span="24" :md="{span: 6, offset: 1}" @click.native="$router.push('/cert/wwhk')">
            <el-image style="width: 100%;" fit="scale-down" :src="res('wwhk.png')"></el-image>
            <div class="desc">
              <p>{{$t("cert.wwhk")}}</p>
              <p>{{$t("cert.wwhkport")}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>


<script>
import { res } from '@/common'
import axios from 'axios'
import qs from 'qs'
export default {
  data() {
    return {
      name: '',
      email: '',
      comment: '',
      loading: false
    }
  },
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
    submit() {
      if (!this.name) {
        this.$alert(this.$t('cert.nameerr'))
        return
      }
      if (!this.email) {
        this.$alert(this.$t('cert.emailerr'))
        return
      }
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.email)) {
        this.$alert(this.$t('cert.emailformaterr'))
        return
      }
      if (!this.comment) {
        this.$alert(this.$t('cert.remark'))
        return
      }
      console.log(this.name, this.email, this.comment)
      const data = {
        company: this.name,
        name: this.name,
        phone: this.email,
        demand: '--',
        addr: '--',
        mes: this.comment
      }
      data.email = 'luoduan@aimore.com'
      this.loading = true
      axios({
        url: 'https://dev.aimore.com/customer/insert',
        method: 'POST',
        data: qs.stringify(data)
      }).then(ret => {
        console.log('ret:', ret)
        this.$alert(this.$t('cert.submited'))
        this.reset()
      }).finally(() => {
        this.loading = false
      })
    },
    reset() {
      this.name = ''
      this.email = ''
      this.comment = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.section1 {
  margin-bottom: var(--margin-max-max);

  .el-col {
    margin-top: var(--margin-max-max);
    transition: all .3s ease;
  }

  & > div {
    position: relative;
    .el-image {
      display: block;
    }

    .desc {
      left: 0;
      bottom: 0;
      padding: 10px 16px 10px;
      background-color: #ECECEC;
      min-height: 80px;

      p:first-child {
        font-size: 16px;
        font-weight: 600;
      }
      p:last-child {
        font-size: 13px;
        font-weight: 400;
        margin-top: 6px;
        line-height: 1.33;
      }
    }
  }

  .el-col:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
}
</style>