<template>
  <div class="module page-wrapper">
    <div class="container">
      <div class="row">
        <el-row :gutter="48" class="section1">
          <el-col :span="12" :xs="{span: 24}">
            <img src="../../assets/module_wb201.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{span: 24}">
            <div class="plain">
              <p><span>{{$t("module.attr1")}}</span><span>{{$t("module.attr1v")}}</span></p>
              <p><span>{{$t("module.attr2")}}</span><span>WI-FI&amp;BLE</span></p>
              <p><span>{{$t("module.attr3")}}</span><span>{{$t("module.attr3v")}}</span></p>
              <p><span>{{$t("module.attr4")}}</span><span>{{$t("module.attr4v")}}</span></p>
              <p><span>{{$t("module.attr5")}}</span><span>{{$t("module.attr5v")}}</span></p>
              <p><span>{{$t("module.attr6")}}</span><span>30*14*10mm</span></p>
              <p><span>{{$t("module.attr7")}}</span><span>{{$t("module.attr7v")}}</span></p>
            </div>
            <div class="feature">
              <div>
                <img src="../../assets/icon_gaoya.png" alt="">
                <label for="">{{$t("module.featurea1")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_jicha.png" alt="">
                <label for="">{{$t("module.featurea2")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_wxwg.png" alt="">
                <label for="">{{$t("module.featurea3")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_ldzn.png" alt="">
                <label for="">{{$t("module.featurea4")}}</label>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="48"  class="section1">
          <el-col :span="12" :xs="{span: 24}">
            <img src="../../assets/module_b3132.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{span: 24}">
            <div class="plain">
              <p><span>{{$t("module.attr1")}}</span><span>{{$t("module.atta1v")}}</span></p>
              <p><span>{{$t("module.attr2")}}</span><span>{{$t("module.atta2v")}}</span></p>
              <p><span>{{$t("module.attr3")}}</span><span>{{$t("module.atta3v")}}</span></p>
              <p><span>{{$t("module.attr4")}}</span><span>{{$t("module.atta4v")}}</span></p>
              <p><span>{{$t("module.attr5")}}</span><span>{{$t("module.atta5v")}}</span></p>
              <p><span>{{$t("module.attr6")}}</span><span>{{$t("module.atta6v")}}</span></p>
              <p><span>{{$t("module.attr7")}}</span><span>{{$t("module.atta7v")}}</span></p>
            </div>
            <div class="feature">
              <div>
                <img src="../../assets/icon_ccdj.png" alt="">
                <label for="">{{$t("module.featureb1")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_jicha.png" alt="">
                <label for="">{{$t("module.featureb2")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_wxwg.png" alt="">
                <label for="">{{$t("module.featureb3")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_ldzn.png" alt="">
                <label for="">{{$t("module.featureb4")}}</label>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="48"  class="section1">
          <el-col :span="12" :xs="{span: 24}">
            <img src="../../assets/module_wbe3.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{span: 24}">
            <div class="plain">
              <p><span>{{$t("module.attr1")}}</span><span>{{$t("module.attb1v")}}</span></p>
              <p><span>{{$t("module.attr2")}}</span><span>{{$t("module.attb2v")}}</span></p>
              <p><span>{{$t("module.attr3")}}</span><span>{{$t("module.attb3v")}}</span></p>
              <p><span>{{$t("module.attr4")}}</span><span>{{$t("module.attb4v")}}</span></p>
              <p><span>{{$t("module.attr5")}}</span><span>{{$t("module.attb5v")}}</span></p>
              <p><span>{{$t("module.attr6")}}</span><span>{{$t("module.attb6v")}}</span></p>
              <p><span>{{$t("module.attr7")}}</span><span>{{$t("module.attb7v")}}</span></p>
            </div>
            <div class="feature">
              <div>
                <img src="../../assets/icon_jicha.png" alt="">
                <label for="">{{$t("module.featurec1")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_wxwg.png" alt="">
                <label for="">{{$t("module.featurec2")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_hwyk.png" alt="">
                <label for="">{{$t("module.featurec3")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_ldzn.png" alt="">
                <label for="">{{$t("module.featurec4")}}</label>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="48"  class="section1">
          <el-col :span="12" :xs="{span: 24}">
            <img src="../../assets/module_ir301.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{span: 24}">
            <div class="plain">
              <p><span>{{$t("module.attr1")}}</span><span>{{$t("module.attc1v")}}</span></p>
              <p><span>{{$t("module.attr2")}}</span><span>{{$t("module.attc2v")}}</span></p>
              <p><span>{{$t("module.attr3")}}</span><span>{{$t("module.attc3v")}}</span></p>
              <p><span>{{$t("module.attr4")}}</span><span>{{$t("module.attc4v")}}</span></p>
              <p><span>{{$t("module.attr5")}}</span><span>{{$t("module.attc5v")}}</span></p>
              <p><span>{{$t("module.attr6")}}</span><span>{{$t("module.attc6v")}}</span></p>
              <p><span>{{$t("module.attr7")}}</span><span>{{$t("module.attc7v")}}</span></p>
            </div>
            <div class="feature">
              <div>
                <img src="../../assets/icon_jicha.png" alt="">
                <label for="">{{$t("module.featured1")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_wxwg.png" alt="">
                <label for="">{{$t("module.featured2")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_hwyk.png" alt="">
                <label for="">{{$t("module.featured3")}}</label>
              </div>
              <div>
                <img src="../../assets/icon_ldzn.png" alt="">
                <label for="">{{$t("module.featured4")}}</label>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.section1 {
  margin: 40px 0;
  .el-col {
    position: relative;
    img {
      width: 100%;
    }
    .plain {
      p {
        padding: 0 24px;
        height: 50px;
        display: flex;
        align-items: center;
        span:first-child {
          display: inline-block;
          width: 4em;
          position: relative;
          text-align-last: justify;
          margin-right: 24px;
          flex: 0 0 auto;
          &::after {
            content: ':';
            display: block;
            position: absolute;
            top: 0;
            right: -0.5em;
          }
        }
      }
      p:nth-child(2n + 1) {
        background-color: #ECECEC;
      }
    }
    .feature {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
      align-items: flex-start;
      & > div {
        width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        flex-grow: 1;
        img {
          width: 80%;
          max-width: 85px;
        }
        label {
          margin: 12px 0;
          text-align: center;
        }
      }
    }
  }
}

</style>