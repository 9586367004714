import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import About from '../views/about/About.vue'
import Layout from '../views/Layout.vue'
import Apps from '../views/service/Apps.vue'
import Cloud from '../views/service/Cloud.vue'
import Module from '../views/service/Module.vue'
import Electric from '../views/solution/Electric.vue'
import Equipment from '../views/solution/Equipment.vue'
import Healthy from '../views/solution/Healthy.vue'
import Light from '../views/solution/Light.vue'
import Thermog from '../views/public/Thermog.vue'
import Capsule from '../views/public/Capsule.vue'
import Bulb from '../views/product/bulb.vue'
import Led from '../views/product/led.vue'
import Air from '../views/product/air.vue'
import Plug from '../views/product/plug.vue'
import DengDai from '../views/product/dengdai.vue'
import NotFound from '../views/NotFound.vue'
import Redirect from '../views/Redirect.vue'
import Cert from '../views/cert/Cert.vue'
import FFS from '../views/cert/FFS.vue'
import WWGA from '../views/cert/WWGA.vue'
import WWHK from '../views/cert/WWHK.vue'
const Contact = import('../views/contact/contact.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      }
    ]
  },
  {
    path: '/service',
    component: Layout,
    children: [
      {
        path: 'apps',
        name: 'Apps',
        component: Apps
      },
      {
        path: 'cloud',
        name: 'Cloud',
        component: Cloud
      },
      {
        path: 'module',
        name: 'Module',
        component: Module
      },
    ]
  },
  {
    path: '/solution',
    component: Layout,
    children: [
      {
        path: 'electric',
        name: 'Electric',
        component: Electric
      },
      {
        path: 'equipment',
        name: 'Equipment',
        component: Equipment
      },
      {
        path: 'healthy',
        name: 'Healthy',
        component: Healthy
      },
      {
        path: 'light',
        name: 'Light',
        component: Light
      },
      {
        path: 'bulb',
        name: 'Bulb',
        component: Bulb
      },
      {
        path: 'led',
        name: 'Led',
        component: Led
      },
      {
        path: 'air',
        name: 'Air',
        component: Air
      },
      {
        path: 'plug',
        name: 'PLUG',
        component: Plug
      },
      {
        path: 'dengdai',
        name: 'DengDai',
        component: DengDai
      }
    ]
  },
  {
    path: '/p',
    component: Layout,
    children: [
      {
        path: 'thermog',
        name: 'Thermog',
        component: Thermog
      },
      {
        path: 'capsule',
        name: 'Capsule',
        component: Capsule
      }
    ]
  },
  {
    path: '/about',
    component: Layout,
    children: [
      {
        path: '',
        name: 'About',
        component: About
      }
    ]
  },
  {
    path: '/cert',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Cert',
        component: Cert
      },
      {
        path: 'ffs',
        name: 'FFS',
        component: FFS
      },
      {
        path: 'wwga',
        name: 'WWGA',
        component: WWGA
      },
      {
        path: 'wwhk',
        name: 'WWHK',
        component: WWHK
      }
    ]
  },
  {
    path: '/contact',
    component: () => Contact
  },
  {
    path: '*/index.html',
    component: Redirect
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  routes,
  mode: 'history'
})

router.afterEach(() => {
  document.documentElement.scrollTop = 0
  document.body.scrollTop = 0
})

window.router = router

export default router
