<template>
<transition name="scale">
  <div class="scroll-top" @click="scrollToTop" v-if="scrollTop > 100">
    <img src="../assets/TOP-white.svg" alt="">
  </div>
</transition>
</template>

<script>
export default {
  data() {
    return {
      scrollTop: 0
    }
  },
  mounted() {
    this.onScroll()
  },
  methods: {
    async onScroll() {
      await this.$nextTick()
      window.addEventListener('scroll', e => {
        console.log('高度', e.target.scrollingElement.scrollTop)
        this.scrollTop = e.target.scrollingElement.scrollTop
        this.scrollingElement = e.target.scrollingElement
      })
    },
    scrollToTop() {
      let start
      let scrollTop = this.scrollTop

      const step = timestamp => {
        if (start == undefined) start = timestamp

        const elapsed = timestamp - start
        console.log('elapsed', elapsed)

        // if (/chrome/i.test(navigator.userAgent)) {
        //   document.documentElement.scrollTop = scrollTop - Math.min(elapsed / 300 * scrollTop , scrollTop);
        // } else {
        //   this.scrollingElement.scrollTop = scrollTop - Math.min(elapsed / 300 * scrollTop , scrollTop);
        // }

        this.scrollingElement.scrollTop = scrollTop - Math.min(elapsed / 300 * scrollTop , scrollTop);

        if (elapsed < 300) { // 在两秒后停止动画
          window.requestAnimationFrame(step);
        }
      }

      requestAnimationFrame(step)
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: rgba(0, 0, 0, .2);
  border-radius: 50%;
  padding: 8px;
  height: 42px;
  width: 42px;
  transition: all .3s ease;

  img {
    width: 100%;
  }

  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, .3);
  }
}
.scale-enter, .scale-leave-to {
  transform: scale(0);
  opacity: 0;
}
.scale-enter-active, .scale-leave-active {
  transition: all .3s ease;
}
</style>