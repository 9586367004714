<template>
  <div class="bulb page-wrapper">
    <div class="container">
      <div class="row">
        <div style="overflow:hidden">
          <el-row class="section1" :gutter="120">
            <el-col :span="12" :xs="{ span: 24 }">
              <img src="../../assets/led_image.png" alt="">
            </el-col>
            <el-col :span="12" :xs="{ span: 24 }">
              <h3>{{$t("led.title")}}</h3>
              <p>{{$t("led.feature1")}}  |  {{$t("led.feature2")}}   |  {{$t("led.feature3")}}  |  {{$t("led.feature4")}}</p>
              <div class="feature">
                <div>
                  <div>
                    <img src="../../assets/bulb_ln.png" alt="">
                    <label for="">{{$t("led.coldturner")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/bulb_ld.png" alt="">
                    <label for="">{{$t("led.lightchanger")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/led_jm.png" alt="">
                    <label for="">{{$t("led.whitedark")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/bulb_dz.png" alt="">
                    <label for="">{{$t("led.manycontrol")}}</label>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="../../assets/bulb_zn.png" alt="">
                    <label for="">{{$t("led.smartconnect")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/bulb_ds.png" alt="">
                    <label for="">{{$t("led.timercontrol")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/bulb_djs.png" alt="">
                    <label for="">{{$t("led.countdown")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/bulb_qj.png" alt="">
                    <label for="">{{$t("led.scenechange")}}</label>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="split"></div>
    <div class="container">
      <div class="row">
        <img class="section2" :src="res('led_xq.png')" alt="">
      </div>
    </div>
  </div>  
</template>

<script>
import { res } from '@/common'
export default {
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
  }
}
</script>

<style lang="scss" scoped>
.section1 {
  margin: 40px 0 60px;
  .el-col {
    position: relative;
    img {
      width: 100%;
    }
  }
  .el-col:last-child {
    h3 {
      font-size: 28px;
      font-weight: normal;
      margin-bottom: 16px;
    }
  }
  .feature {
    margin-top: 60px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 36px;
      label {
        display: block;
        margin-top: 16px;
      }
    }
  }
}
.section2 {
  margin: 40px 0;
  max-width: 100%;
}
.section1 .feature > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  justify-content: space-between;
  img {
    width: auto;
  }
}
.split {
  height: var(--margin-small);
  background-color: #ECECEC;
}
</style>