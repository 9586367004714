import storage from 'good-storage'

const CACHE_LANGUAGE = 'CACHE_LANGUAGE'

export function getLanguage()
{
  return storage.get(CACHE_LANGUAGE)
}

export function setLanguage(val)
{
  return storage.set(CACHE_LANGUAGE, val)
}