<template>
  <div class="thermog page-wrapper">
    <el-image
      class="banner"
      style="width: 100%;"
      :src="require('../../assets/tg_banner.png')"
      fit="scale-down">
    </el-image>
    <div class="container">
      <div class="row">
        <h2 class="section1">
          {{$t("thermog.title")}}
        </h2>
        <el-row class="section2" :gutter="40">
          <el-col :span="12" :xs="{span: 24}">
            <img src="../../assets/tg_photo1.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{span: 24}">
            <div class="text">
              <h3>{{$t("thermog.feature1")}}</h3>
              <p>{{$t("thermog.feature1desc")}}</p>
            </div>
          </el-col>
        </el-row>
        <el-row class="section3" :gutter="40">
          <el-col :span="12" :xs="{span: 24}" class="text">
            <div class="text">
              <h3>{{$t("thermog.feature2")}}</h3>
              <p>{{$t("thermog.feature2desc")}}</p>
            </div>
          </el-col>
          <el-col :span="12" :xs="{span: 24}">
            <img src="../../assets/tg_photo2.png" alt="">
          </el-col>
        </el-row>
        <el-row class="section4" :gutter="40">
          <el-col :span="12"  :xs="{span: 24}">
            <img src="../../assets/tg_photo3.png" alt="">
          </el-col>
          <el-col :span="12"  :xs="{span: 24}">
            <div class="text">
              <h3>{{$t("thermog.featurea")}}</h3>
              <p>{{$t("thermog.featureb")}}</p>
              <p>{{$t("thermog.featurec")}}</p>
              <p>{{$t("thermog.featured")}}</p>
            </div>
          </el-col>
        </el-row>
        <el-row class="section5" :gutter="40">
          <el-col :span="12" :xs="{span: 24}">
            <img src="../../assets/tg_photo4.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{span: 24}">
            <div class="plain">
              <p><span>{{$t("thermog.attr1")}}</span><span>{{$t("thermog.attr1v")}}</span></p>
              <p><span>{{$t("thermog.attr2")}}</span><span>{{$t("thermog.attr2v")}}</span></p>
              <p><span>{{$t("thermog.attr3")}}</span><span>{{$t("thermog.attr3v")}}</span></p>
              <p><span>{{$t("thermog.attr4")}}</span><span>{{$t("thermog.attr4v")}}</span></p>
              <p><span>{{$t("thermog.attr5")}}</span><span>{{$t("thermog.attr5v")}}</span></p>
              <p><span>{{$t("thermog.attr6")}}</span><span>{{$t("thermog.attr6v")}}</span></p>
              <p><span>{{$t("thermog.attr7")}}</span><span>{{$t("thermog.attr7v")}}</span></p>
              <p><span>{{$t("thermog.attr8")}}</span><span>{{$t("thermog.attr8v")}}</span></p>
              <p><span>{{$t("thermog.attr9")}}</span><span>{{$t("thermog.attr9v")}}</span></p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.section1 {
  font-size: 38px;
  font-weight: normal;
  text-align: center;
  margin: 40px 0;
}
.section2,
.section3,
.section4,
.section5 {
  margin: 120px 0;

  .el-col {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: var(--margin-large);

    img {
      max-width: 100%;
    }
    .text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 400px;

      h3 {
        margin: 40px 0;
        text-align: center;
      }

      p {
        line-height: 1.66;
      }
    }
  }
}

.section2 {
  margin-top: 0;
}

.plain {
  p {
    padding: 0 24px;
    height: 50px;
    display: flex;
    align-items: center;
    
    span:first-child {
      display: inline-block;
      width: 4em;
      position: relative;
      text-align-last: justify;
      margin-right: 24px;
      &::after {
        content: ':';
        display: block;
        position: absolute;
        top: 0;
        right: -0.5em;
      }
    }
  }
  p:nth-child(2n + 1) {
    background-color: #ECECEC;
  }
}
</style>

