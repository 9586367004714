export function remoteLoad (url, hasCallback) {
  return createScript(url)
  /**
   * 创建script
   * @param url
   * @returns {Promise}
   */
  function createScript (url) {
    var scriptElement = document.createElement('script')
    document.body.appendChild(scriptElement)
    var promise = new Promise((resolve, reject) => {
      scriptElement.addEventListener('load', e => {
        removeScript(scriptElement)
        if (!hasCallback) {
          resolve(e)
        }
      }, false)

      scriptElement.addEventListener('error', e => {
        removeScript(scriptElement)
        reject(e)
      }, false)

      if (hasCallback) {
        window.____callback____ = function () {
          resolve()
          window.____callback____ = null
        }
      }
    })

    if (hasCallback) {
      url += '&callback=____callback____'
    }

    scriptElement.src = url

    return promise
  }

  /**
   * 移除script标签
   * @param scriptElement script dom
   */
  function removeScript (scriptElement) {
    document.body.removeChild(scriptElement)
  }
}

export function throttle(delay, action) {
  let last = 0
  let timer = null
  return function() {
      let ctx = this
      let curr = +new Date()
      if (curr - last > delay) {
          clearTimeout(timer)
          action.apply(ctx, arguments)
          last = curr
      } else {
          clearTimeout(timer)
          timer = setTimeout(function() {
              action.apply(ctx, arguments)
              last = curr
          }, delay)
      }
  }
}

export function res_zh(name) {
  return require(`@/assets/${name}`)
}

export function res_en(name) {
  return require(`@/assets_en/${name}`)
}

export function res(name, locale) {
  if (locale == 'zh-CN') {
    return res_zh(name)
  } else {
    return res_en(name)
  }
}