<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { remoteLoad } from '@/common/index'
export default {
  created() {
    remoteLoad('//at.alicdn.com/t/font_2413994_mwjupzrbsjg.js')
  }
}

</script>
<style lang="scss">
</style>
