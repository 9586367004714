<template>
  <div class="bulb page-wrapper">
    <div class="container">
      <div class="row">
        <div style="overflow:hidden">
          <el-row class="section1" :gutter="120">
            <el-col :span="12" :xs="{ span: 24 }">
              <img src="../../assets/plug_img.png" alt="">
            </el-col>
            <el-col :span="12" :xs="{ span: 24 }">
              <h3>{{$t("plug.title")}}</h3>
              <p>{{$t("plug.feature1")}}  |  {{$t("plug.feature2")}}   |  {{$t("plug.feature3")}}  |  {{$t("plug.feature4")}}</p>
              <div class="feature">
                <div>
                  <div>
                    <img src="../../assets/icon_sskz.png" alt="">
                    <label for="">{{$t("plug.coldturner")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/icon_yjzx.png" alt="">
                    <label for="">{{$t("plug.lightchanger")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/icon_dssz.png" alt="">
                    <label for="">{{$t("plug.whitelight")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/icon_znld.png" alt="">
                    <label for="">{{$t("plug.manycontrol")}}</label>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="../../assets/icon_yykz.png" alt="">
                    <label for="">{{$t("plug.smartconnect")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/icon_tssz.png" alt="">
                    <label for="">{{$t("plug.timercontrol")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/bulb_djs.png" alt="">
                    <label for="">{{$t("plug.countdown")}}</label>
                  </div>
                  <div>
                    <img src="../../assets/bulb_qj.png" alt="">
                    <label for="">{{$t("plug.scene")}}</label>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="split"></div>
    <div class="container">
      <div class="row">
        <img class="section2" :src="res('plug_fe.png')" alt="">
      </div>
    </div>
  </div>  
</template>

<script>
import { res } from '@/common'
export default {
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
  }
}
</script>

<style lang="scss" scoped>
.section1 {
  margin: 40px 0 60px;
  .el-col {
    position: relative;
    img {
      width: 100%;
    }
  }
  .el-col:last-child {
    h3 {
      font-size: 28px;
      font-weight: normal;
      margin-bottom: 16px;
    }
  }
  .feature {
    margin-top: 60px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 36px;
      label {
        display: block;
        margin-top: 16px;
      }
    }
  }
}
.section2 {
  margin: 40px 0;
  max-width: 100%;
}
.section1 .feature > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  justify-content: space-between;
  img {
    width: auto;
  }
}

.split {
  height: var(--margin-small);
  background-color: #ECECEC;
}
</style>