<template>
  <div class="cloud page-wrapper">
    <el-image
      class="banner"
      style="width: 100%;"
      :src="res('app_banner.png')"
      fit="scale-down">
    </el-image>

    <div class="container">
      <div class="row">
        <el-row class="section1" :gutter="40">
          <el-col :span="12" :xs="{ span: 24 }">
            <img :src="res('app_image.png')" alt="">
          </el-col>
          <el-col :span="12" :xs="{ span: 24 }">
            <h2>{{$t("apps.allinone")}}</h2>
            <div>
              {{$t("apps.allinonedesc")}}
            </div>
          </el-col>
        </el-row>
        <h2 class="section2">{{$t("apps.mainfunc")}}</h2>
        <el-row class="section3" :gutter="24">
          <el-col :span="8" :xs="{ span: 24 }">
            <img src="../../assets/app_icon1.png" alt="">
            <h3>{{$t("apps.accountfunc")}}</h3>
            <div>
              <p>{{$t("apps.unregister")}}</p>
              <p>{{$t("apps.emailsupport")}}</p>
              <p>{{$t("apps.qqlogin")}} </p>
            </div>
          </el-col>
          <el-col :span="8" :xs="{ span: 24 }">
            <img src="../../assets/app_icon2.png" alt="">
            <h3>{{$t("apps.homesupport")}}</h3>
            <div>
              <p>{{$t("apps.peoplesupport")}} </p>
              <p>{{$t("apps.weather")}}</p>
            </div>
          </el-col>
          <el-col :span="8" :xs="{ span: 24 }">
            <img src="../../assets/app_icon3.png" alt="">
            <h3>{{$t("apps.device")}}</h3>
            <div>
              <p>{{$t("apps.networksupport")}}</p>
              <p>{{$t("apps.manualcontrol")}}</p>
              <p>{{$t("apps.deviceshare")}} </p>
            </div>
          </el-col>
          <el-col :span="8" :xs="{ span: 24 }">
            <img src="../../assets/app_icon4.png" alt="">
            <h3>{{$t("apps.smartmodule")}}</h3>
            <div>
              <p>{{$t("apps.oneclick")}}</p>

            </div>
          </el-col>
          <el-col :span="8" :xs="{ span: 24 }">
            <img src="../../assets/app_icon5.png" alt="">
            <h3>{{$t("apps.msgpush")}}</h3>
            <div>
              <p>{{$t("apps.pushintergrate")}}</p>
              <p>{{$t("apps.pushhide")}}</p>
            </div>
          </el-col>
          <el-col :span="8" :xs="{ span: 24 }">
            <img src="../../assets/app_icon6.png" alt="">
            <h3>{{$t("apps.feedback")}}</h3>
            <div>
              <p>{{$t("apps.viewapp")}} </p>
              <p>{{$t("apps.viewfeed")}} </p>
              <p>{{$t("apps.uplog")}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { res } from '@/common'
export default {
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
  }
}
</script>

<style lang="scss" scoped>
.section1 {
  margin: 100px 0;

  .el-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 100%;
    }
  }
  .el-col:last-child {
    h2 {
      margin: 50px 0;
    }
    & > div {
      line-height: 1.5;

    }
  }
}

.section2 {
  text-align: center;
  margin: 40px 0 60px;
}

.section3 {
  margin: 40px 0 100px;
  .el-col {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 16px 0;

    h3 {
      margin: 16px 0;
      font-size: 22px;
      font-weight: normal;
    }

    p {
      line-height: 1.5;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 991px) {
  .section3 {
    img {
      height: 56px;
    }
  }
}
</style>