<template>
  <div class="bulb page-wrapper">
    <div class="container">
      <div class="row">
        <div style="overflow:hidden">
          <el-row class="section1" :gutter="120">
            <el-col :span="12" :xs="{ span: 24 }">
              <img :src="res('wwhk_img.png')" alt="">
            </el-col>
            <el-col :span="12" :xs="{ span: 24 }">
              <h3>{{$t("wwhk.cert")}}</h3>
              <p class="desc"><img class="flag" src="../../assets/flag.png" />{{$t("wwhk.certdesc")}}</p>
              <p class="text">{{$t("wwhk.certtext")}} </p>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="split"></div>
    <div class="container">
      <div class="row full">
        <img class="section2" :src="res('wwhk_img2.png')" alt="">
      </div>
    </div>
  </div>  
</template>

<script>
import { res } from '@/common'
export default {
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
  }
}
</script>


<style lang="scss" scoped>
.section1 {
  margin: 40px 0 60px;
  .el-col {
    position: relative;
    img {
      width: 100%;
    }
  }
  .el-col:last-child {
    h3 {
      font-size: 28px;
      font-weight: normal;
      margin-bottom: 16px;
    }
  }
  .feature {
    margin-top: 60px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 36px;
      label {
        display: block;
        margin-top: 16px;
      }
    }
  }
}
.section2 {
  margin: 40px 0;
  max-width: 100%;
}
.section1 .feature > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  justify-content: space-between;
  img {
    width: auto;
  }
}

.split {
  height: var(--margin-small);
  background-color: #ECECEC;
}

p.desc {
  color: #37ACFD;
  font-size: 18px;
}

p.text {
  margin-top: 16px;
  line-height: 1.33;
  font-weight: 300;
}

.section1 img.flag {
  height: 20px;
  width: auto;
  vertical-align: bottom;
  margin-right: 12px;
}
</style>