<template>
  <nav class="app-header">
    <div class="container">
      <div class="row">

        <div class="header-nav v-pc">
          <img class="header-nav-logo" :src="res('dayu_logo.png')" />

          <div class="nav-link v-pc" v-for="(item, index) in menu" :key="index">
            <router-link class="link-item" v-if="!item.children" :to="item.url">
              <span class="link-item-title">{{item.title}}</span>
            </router-link>
            <div class="link-item" v-else @mouseenter="onMouseenter(item)" @mouseleave="onMouseleave(item)"
              :class="{'router-link-active': $route.path.startsWith(item.url)}">
              <router-link v-if="item.nav" :to="item.url" tag="span" class="link-item-title nav">{{item.title}}</router-link>
              <span v-else class="link-item-title">{{item.title}}</span>
              <el-collapse-transition>
                <ul class="link-item-option" v-show="item.showmenu">
                  <router-link v-for="(k,d) in item.children" :key="d" :to="k.url" v-slot="{ navigate }" custom>
                    <li @click="navigate" @keypress.enter="navigate" role="link" 
                    :class="{'router-link-exact-active': $route.path == k.url }">{{k.title}}</li>
                  </router-link>
                </ul>
              </el-collapse-transition>
            </div>
          </div>
        </div>

        <div class="header-nav v-mobile">
          <img class="header-nav-logo" :src="res('dayu_logo_mini.png')" />
          
          <el-button @click="menuvisible=!menuvisible">
            <icon name="icon-zhedie" ></icon>
          </el-button>

          <el-collapse-transition>
            <div class="nav-link-wrapper" v-show="menuvisible">
              <div class="nav-link" v-for="(item, index) in menu" :key="index" >
                <router-link class="link-item" v-if="!item.children" :to="item.url">
                  <span class="link-item-title">{{item.title}}</span>
                </router-link>
                <div class="link-item" v-else
                  :class="{'router-link-active': $route.path.startsWith(item.url) }">
                  <router-link v-if="item.nav" :to="item.url" tag="span" class="link-item-title nav">{{item.title}}</router-link>
                  <span v-else class="link-item-title">{{item.title}}</span>
                  <ul class="link-item-option">
                    <router-link v-for="(k,d) in item.children" :key="d" :to="k.url" v-slot="{ navigate }" custom>
                      <li @click="navigate" @keypress.enter="navigate" role="link" 
                      :class="{'router-link-exact-active': $route.path == k.url }">{{k.title}}</li>
                    </router-link>
                  </ul>
                </div>
              </div>
            </div>
          </el-collapse-transition>

          <transition name="el-fade-in">
            <div class="mask" v-if="menuvisible" @click="menuvisible=false"></div>
          </transition>
          
        </div>

      </div>

      <div class="language" @click="onChangeLanguage">
        <img src="../assets/language.svg" /> {{$t("header.language")}}
      </div>
    </div>
  </nav>
</template>

<script>
import { res } from '@/common'
import { setLanguage } from '@/common/cache'
export default {
  data() {
    return {
      menu: [
        { title: '首页', url: '/home', children: null, showmenu: false },
        { title: '产品与服务', url: '/service', children: [
          { title: '云开发', url: '/service/cloud' },
          { title: 'APPS', url: '/service/apps' },
          { title: '模组', url: '/service/module' },
        ], showmenu: false },
        { title: '解决方案', url: '/solution', children: [
          { title: '智能照明', url: '/solution/light' },
          { title: '智能健康', url: '/solution/healthy' },
          { title: '智能电工', url: '/solution/electric' },
          { title: '智能家电', url: '/solution/equipment' },
        ], showmenu: false },
        { title: '认证服务', url: '/cert', nav: true, children: [
          { title: 'WWHK', url: '/cert/wwhk' },
          { title: 'WWGA', url: '/cert/wwga' },
          { title: 'FFS', url: '/cert/ffs' }
        ], showmenu: false },
        { title: '关于大于', url: '/about', children: null, showmenu: false },
      ],
      menuvisible: false
    }
  },
  created() {
    this.refreshMenu()
  },
  watch: {
    menuvisible(newValue) {
      if (newValue) {
        document.body.classList.add('app-body--hidden')
      } else {
        document.body.classList.remove('app-body--hidden')
      }
    },
    '$i18n.locale': {
      handler() {
        this.refreshMenu()
      }
    }
  },
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
    onMouseenter(item) {
      item.showmenu = true
    },
    onMouseleave(item) {
      item.showmenu = false
    },
    onMenuClick(item) {
      item.showmenu = false
    },
    onChangeLanguage() {
      if (this.$i18n.locale === 'zh-CN') {
        this.$i18n.locale = 'en-US'
      } else {
        this.$i18n.locale = 'zh-CN'
      }
      setLanguage(this.$i18n.locale)
    },
    refreshMenu() {
      const menu = [
        { title: this.$t('menu.home'), url: '/home', children: null, showmenu: false },
        { title: this.$t('menu.service'), url: '/service', children: [
          { title: this.$t('menu.clouddev'), url: '/service/cloud' },
          { title: this.$t('menu.apps'), url: '/service/apps' },
          { title: this.$t('menu.modules'), url: '/service/module' },
        ], showmenu: false },
        { title: this.$t('menu.solution'), url: '/solution', children: [
          { title: this.$t('menu.smartlight'), url: '/solution/light' },
          { title: this.$t('menu.smarthealth'), url: '/solution/healthy' },
          { title: this.$t('menu.smartele'), url: '/solution/electric' },
          { title: this.$t('menu.smarthome'), url: '/solution/equipment' },
        ], showmenu: false },
        { title: this.$t('menu.cert'), url: '/cert', nav: true, children: [
          { title: 'WWHK', url: '/cert/wwhk' },
          { title: 'WWGA', url: '/cert/wwga' },
          { title: 'FFS', url: '/cert/ffs' }
        ], showmenu: false },
        { title: this.$t('menu.about'), url: '/about', children: null, showmenu: false },
      ]

      this.menu = menu
    }
  }
}
</script>

<style lang="scss" scoped>
.header-nav.v-pc {
  display: flex;
  height: 88px;
  align-items: center;
  justify-content: space-between;

  .header-nav-logo {
    height: 63px;
  }
}

.header-nav.v-mobile {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;

  .header-nav-logo {
    width: 140px;
  }
}

.nav-link.v-pc {
  .link-item {
    position: relative;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    width: 120px;

    &.router-link-exact-active, &.router-link-active {
      .link-item-title {
        color: var(--color-primary);
        &::after {
          width: calc(100% + 8px);
        }
      }
    }

    .link-item-title {
      color: #666666;
      cursor: pointer;
      text-decoration: none;
      font-weight: 500;
      text-align: center;
      padding: 12px 0;
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -4px;
        bottom: 6px;
        height: 2px;
        background-color: var(--color-primary);
        width: 12px;
        transition: all .3s linear;
      }
    }

    .link-item-option {
      list-style: none;
      position: absolute;
      z-index: 100;
      left: 0;
      top: 100%;
      background-color: #FFF;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

      li {
        padding: 16px 0;
        white-space: nowrap;
        width: 120px;
        text-align: center;
        color: #666666;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
          color: var(--color-primary);
        }

        &.router-link-exact-active {
          color: var(--color-primary);
        }
      }
    }
  }
}

.header-nav.v-mobile .nav-link-wrapper {
  position: absolute;
  left: -24px;
  bottom: 2px;
  transform: translateY(100%);
  background-color: #FFF;
  width: calc(100% + 48px);
  padding: 0 24px;
  z-index: 100;
}

.header-nav.v-mobile .mask {
  position: fixed;
  left: -24px;
  bottom: 0;
  background-color: rgba(0,0,0,.38);
  width: calc(100% + 48px);
  padding: 0 24px;
  top: 60px;
  transition: all .3s ease;
}

.link-item-title {
  user-select: none;
}

.header-nav.v-mobile .nav-link {
  .link-item {
    position: relative;
    text-decoration: none;
    display: block;
    text-align: left;

    &.router-link-exact-active, &.router-link-active {
      .link-item-title {
        color: var(--color-primary);
        &::after {
          width: calc(100% + 8px);
        }
      }
    }

    .link-item-title {
      color: #666666;
      cursor: pointer;
      text-decoration: none;
      font-weight: 500;
      text-align: center;
      padding: 12px 0;
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -4px;
        bottom: 6px;
        height: 2px;
        background-color: var(--color-primary);
        width: 12px;
        transition: all .3s linear;
      }
    }

    .link-item-option {
      list-style: none;
      background-color: #FFF;
      margin-left: 1em;
      position: relative;

      &::after {
        content: '';
        z-index: 1;
        position: absolute;
        height: calc(100% - 16px);
        top: 8px;
        left: 0;
        width: 1px;
        background-color: #eee;
      }

      li {
        padding: 8px 16px;
        white-space: nowrap;
        text-align: left;
        color: #666666;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
          color: var(--color-primary);
        }

        &.router-link-exact-active {
          color: var(--color-primary);
        }

        &:focus {
          -webkit-tap-highlight-color: rgba(0,0,0,0);
        }
      }
    }
  }
}

span, div, a, p, li, ul {
  &:focus {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}
</style>