<template>
  <div class="about page-wrapper">
    <el-image
      class="banner"
      style="width: 100%;"
      :src="require('../../assets/about_banner.png')"
      fit="scale-down">
    </el-image>

    <div class="container">
      <div class="row">
        <div class="section0">
          <el-image
            style="height:73px;"
            :src="require('../../assets/about_about.png')"
            fit="scale-down">
          </el-image>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="section1">
          {{$t("about.text1")}}
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="section2">
          <el-image
            style="width:100%;"
            :src="require('../../assets/about_digital.png')"
            fit="scale-down">
          </el-image>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="section3">
          <img src="../../assets/about_brand.png" alt="">
        </div>
      </div>
    </div>

    <div class="section4 v-pc" >
      <el-image
        style="width: 100%;"
        :src="require('../../assets/about_de.png')"
        fit="cover">
      </el-image>
    </div>

    <div class="container v-mobile">
      <div class="row">
        <div class="section4">
          <el-image
            v-if="false"
            style="width: 100%;"
            :src="require('../../assets/about_brand_bk.png')"
            fit="cover">
          </el-image>

          <div class="section4-content">
            <p class="text1">
              深圳大于智能有限公司是行业领先的AloT物联网运营服务商，专注于赋能传统照明安防、雷达看护等垂直行业设备厂一站式智能化升级。大于智能自主研发架构的、AIMORE AloT云开发平台，面向智慧家用照明、商业照明、智能家居及智能安防等垂直行业，提供端到端整体智能化解决方案。核心业务包括企业级云连接、云智能服务，通过接入现有设备将云服务能力赋予终端用户，实现传统制造品牌、OEM厂商、消费者等智能化需求。
            </p>
            <p class="text2">
              AI MORE敏捷物联网平台通过极速物联技术及平台强大的可拓展性、丰富的通用功能组件库，兼容多种物联常用传输协议，最大限度降低硬件开发技术门槛及研发成本，助力全球垂直行业用户零风险、免开发、短时间、高标准快速落地 AloT 产品，行业一站式云加端解决方案助力客户完成商业模式快速升级，提升产品竞争力与附加值。
            </p>
            <p class="text3">
              Shenzhen DAYU Intelligence Co.,Ltd.is the industry's leading AloT Internet of Things operation service provider,focusing on enabling one-stop intelligent upgrades for equipment factories in vertical industries such as traditional lighting,security,and radar care. The AIMORE AloT cloud development platform,which is larger than intelligent independent research and development architecture,provides end-to-end overall intelligence for vertical industries such as smart home lighting,commercial lighting,smart home,and smart security. Energy and chemicai solutions.The core business includes enterprise-level cloud connection,cloud intelligent service,and empowers end users with cloud service capabilities through access to existing equipment,realizing traditional manufacturing brands,OEM manufacturers. Consumers and other intelligent needs.
            </p>
            <p class="text4">
              AI MORE agile loT platform is compatible with a variety of commonly used loT transmission protocols through ultra-fast loT technology and the platform's powerful scalability,rich common functional component library,and minimizes
              Hardware development technology thresholds and R&amp;D costs help global vertical industry users to quickly implement AloT products with zero risk,no development,short time, high standards,and industry one-stop cloud plus end solutions
              Assist customers to complete the rapid upgrade of business models and enhance product competitiveness and added value.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container section7">
      <div class="row">
        <el-image
          style="height:59px;margin:32px 0;"
          :src="require('../../assets/about_contact.png')"
          fit="scale-down">
        </el-image>
      </div>
    </div>

    <div class="container section5">
      <div class="row">
        <el-row :gutter="16">
          <el-col :span="12">
            <el-input v-model="name" :placeholder="$t('about.name')" maxlength="10"></el-input>
          </el-col>
          <el-col :span="12">
            <el-input v-model="email" :placeholder="$t('about.email')"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 10}"
              :placeholder="$t('about.remark')"
              v-model="comment">
            </el-input>
          </el-col>
        </el-row>
        <div class="btngroup">
          <el-button type="primary" @click="submit" :loading="loading">{{$t('about.submit')}}</el-button>
          <el-button type="default" @click="reset">{{$t('about.reset')}}</el-button>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import axios from 'axios'
import qs from 'qs'
export default {
  data() {
    return {
      name: '',
      email: '',
      comment: '',
      loading: false
    }
  },
  methods: {
    submit() {
      if (!this.name) {
        this.$alert(this.$t('about.namerr'))
        return
      }
      if (!this.email) {
        this.$alert(this.$t('about.emailerr'))
        return
      }
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.email)) {
        this.$alert(this.$t('about.emailformaterr'))
        return
      }
      if (!this.comment) {
        this.$alert(this.$t('about.remarkerr'))
        return
      }
      console.log(this.name, this.email, this.comment)
      const data = {
        company: this.name,
        name: this.name,
        phone: this.email,
        demand: '--',
        addr: '--',
        mes: this.comment
      }
      data.email = 'luoduan@aimore.com'
      this.loading = true
      axios({
        url: 'https://dev.aimore.com/customer/insert',
        method: 'POST',
        data: qs.stringify(data)
      }).then(ret => {
        console.log('ret:', ret)
        this.$alert(this.$t('about.submitsuccess'))
        this.reset()
      }).finally(() => {
        this.loading = false
      })
    },
    reset() {
      this.name = ''
      this.email = ''
      this.comment = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.section0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--margin-normal);
}
.section1 {
  margin: 60px 0 100px;
  line-height: 1.33;
}
.section3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--margin-normal) 0;
  position: relative;

  img {
    max-width: 100%;
  }
}
.section4 {
  .section4-content {
    .header1 {
      font-size: 40px;
      margin: 70px 0 60px;
    }
    .text1 {
      line-height: 1.5;
      font-size: 14px;
    }
    .text2 {
      margin-top: 36px;
      line-height: 1.5;
      font-size: 14px;
    }
    .text3 {
      margin-top: 36px;
      line-height: 1.5;
      font-size: 14px;
    }
    .text4 {
      margin-top: 36px;
      line-height: 1.5;
      font-size: 14px;
    }
  }
}
.section5 {
  .el-input, .el-input::v-deep input {
    border-radius: 2px;
  }
  .el-textarea {
    margin-top: 16px;
  }
  .btngroup {
    text-align: center;
    margin: 32px 0 168px;
  }
  .el-button {
    min-width: 140px;
    border-radius: 32px;
  }
}
.section7 {
  margin: 120px 0 40px;
}
</style>