<template>
  <div class="solution page-wrapper">
    <el-image
      class="banner"
      style="width: 100%;"
      :src="res('sl_banner3.png')"
      fit="scale-down">
    </el-image>
    <div class="container">
      <div class="row">
        <el-row class="section1">
          <el-col :span="12" :xs="{ span: 24 }">
            <img src="../../assets/sl_image3.png" alt="">
          </el-col>
          <el-col :span="12" :xs="{ span: 24 }">
            {{$t("elec.title")}}
          </el-col>
        </el-row>
        <hr class="section2"/>
        <h2 class="section3">{{$t("elec.desc")}}</h2>
        <div class="section4">
          <!-- <img :src="`../../assets/icon-left.png`" alt=""> -->
          <el-row :gutter="0">
            <el-col :span="8" :xs="{ span: 24 }"  class="link" @click.native="$router.push('/solution/plug')">
              <img src="../../assets/sl_slider7.png" alt="">
              <span>{{$t("elec.feature1")}}</span>
            </el-col>
            <el-col :span="8" :xs="{ span: 24 }">
              <img src="../../assets/sl_slider8.png" alt="">
              <span>{{$t("elec.feature2")}}</span>
            </el-col>
            <el-col :span="8" :xs="{ span: 24 }">
              <img src="../../assets/sl_slider9.png" alt="">
              <span>{{$t("elec.feature3")}}</span>
            </el-col>
          </el-row>
          <!-- <img :src="`../../assets/icon-right.png`" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { res } from '@/common'
export default {
  methods: {
    res(name) {
      return res(name, this.$i18n.locale)
    },
  }
}
</script>

<style lang="scss" scoped>
.section1 {
  margin: 40px 0;
  position: relative;
  .el-col {
    height: 100%;
    position: relative;
    img {
      width: 100%;
    }
  }
  .el-col:last-child {
    height: 350px;
    display: flex;
    align-items: center;
    line-height: 1.66;
  }
}
.section2 {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #DDD;
}
.section3 {
  text-align: center;
  margin: 100px 0 40px;
}
.section4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 160px;
  & > img {
    flex: 0 0 auto;
  }
  .el-row {
    flex: 1 1 auto;
    .el-col {
      position: relative;
      text-align: center;

      & span {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>